import './ProjectCard.scss';
import * as ReactIconsAI from 'react-icons/ai';
import * as ReactIconsFA from 'react-icons/fa';

const linksTypeDefinitions: { [key: string]: any } = {
    github: {
        name: 'GitHub',
        icon: <ReactIconsAI.AiFillGithub size="16" />,
    },
    live: {
        name: 'Live Site',
        icon: <ReactIconsAI.AiOutlineDesktop size="16" />,
    },
    googleplay: {
        name: 'Google Play',
        icon: <ReactIconsFA.FaGooglePlay size="16" />,
    },
    appstore: {
        name: 'App Store',
        icon: <ReactIconsFA.FaApple size="16" />,
    },
} as const;

interface ProjectCardProps {
    project: { [key: string]: any };
}

function ProjectCard({ project }: ProjectCardProps) {
    return (
        <article className="project-card">
            <div className="project-card__top-row">
                <img
                    src={project.thumbnail}
                    alt={project.title}
                    className="project-card__thumbnail"
                />
            </div>
            <div className="project-card__bottom-row">
                <h3 className="project-card__title">{project.title}</h3>

                <div className="project-card__tech-list">
                    {project.technologies.map(
                        (technology: string, index: number) => (
                            <span
                                className="project-card__tech-item"
                                key={technology}
                            >
                                {technology}
                                {index !== project.technologies.length - 1 &&
                                    ' • '}
                            </span>
                        ),
                    )}
                </div>

                <p className="project-card__description">
                    {project.description}
                </p>

                <div className="project-card__link-list">
                    {project.links.map(
                        (linkData: { type: string; url: string }) => (
                            <div className="project-card__link-item">
                                <a
                                    href={linkData.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="project-card__link"
                                    key={linkData.type}
                                >
                                    {linksTypeDefinitions[linkData.type].icon}
                                    <span className="project-card__link-text">
                                        {
                                            linksTypeDefinitions[linkData.type]
                                                .name
                                        }
                                    </span>
                                </a>
                            </div>
                        ),
                    )}
                </div>
            </div>
        </article>
    );
}
export default ProjectCard;
