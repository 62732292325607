import './ProjectsSection.scss';
import projectsData from '../../data/projects.json';
import ProjectList from './components/ProjectList/ProjectList';

function ProjectsSection() {
    const { projects } = projectsData;

    return (
        <section id="projects" className="projects-section">
            <div className="projects-section__content">
                <h2 className="projects-section__heading">Projects</h2>
                <ProjectList projects={projects} />
            </div>
        </section>
    );
}
export default ProjectsSection;
