import './ContactSection.scss';
import { AiFillGithub, AiOutlineLinkedin, AiOutlineMail } from 'react-icons/ai';
import ContactForm from './components/ContactForm/ContactForm';

function ContactSection() {
    return (
        <section id="contact" className="contact-section">
            <div className="contact-section__content">
                <h2 className="contact-section__heading">Get In Touch!</h2>
                <div className="contact-section__social-list">
                    <a
                        href="https://github.com/ThePinkFedora/"
                        className="contact-section__social-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <AiFillGithub
                            className="contact-section__social-icon"
                            color="#2d3748"
                            size={30}
                        />
                    </a>
                    <a
                        href="https://www.linkedin.com/in/damien-switzer/"
                        className="contact-section__social-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <AiOutlineLinkedin
                            className="contact-section__social-icon"
                            size={30}
                        />
                    </a>
                    <a
                        href="mailto:damienswitzer@hotmail.com"
                        className="contact-section__social-link"
                    >
                        <AiOutlineMail
                            className="contact-section__social-icon"
                            size={30}
                            color="#666"
                        />
                    </a>
                </div>

                <ContactForm />
            </div>
        </section>
    );
}
export default ContactSection;
