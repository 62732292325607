import { useState } from 'react';
import './ContactForm.scss';
import { AiOutlineSend } from 'react-icons/ai';
import { postContactForm } from '../../../../js/contactApi';

interface FormValues {
    fullName: string;
    email: string;
    message: string;
}

function ContactForm() {
    const [formValues, setFormValues] = useState<FormValues>({
        fullName: '',
        email: '',
        message: '',
    });

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        postContactForm({
            name: formValues.fullName,
            email: formValues.email,
            message: formValues.message,
        }).then(() => {
            setFormValues({
                fullName: '',
                email: '',
                message: '',
            });
            alert('Message sent!');
        });
    };

    return (
        <form
            className="contact-form"
            method="POST"
            name="contact"
            onSubmit={handleSubmit}
        >
            <div className="contact-form__container">
                <label className="context-form__form-control">
                    <span className="contact-form__form-control-label">
                        Full Name
                    </span>
                    <input
                        id="fullName"
                        name="fullName"
                        type="text"
                        placeholder="Jane Doe"
                        className="contact-form__form-control-input"
                        value={formValues.fullName}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div className="contact-form__container">
                <label className="contact-form__form-control">
                    <span className="contact-form__form-control-label">
                        Email
                    </span>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="jane@example.com"
                        className="contact-form__form-control-input"
                        value={formValues.email}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div className="contact-form__container">
                <label className="contact-form__form-control">
                    <span className="contact-form__form-control-label">
                        Message
                    </span>
                    <textarea
                        id="message"
                        name="message"
                        className="contact-form__form-control-input"
                        value={formValues.message}
                        onChange={handleInputChange}
                    />
                </label>
            </div>

            <div className="contact-form__container">
                <button className="contact-form__button">
                    <AiOutlineSend
                        className="contact-form__button-icon"
                        size={20}
                    />
                    Send
                </button>
            </div>
        </form>
    );
}
export default ContactForm;
