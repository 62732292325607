import './HeroSection.scss';
import profilePhoto from '../../assets/images/profile_photo.jpg';
import Avatar from '../../components/Avatar/Avatar';
import {
    AiFillGithub,
    AiOutlineLinkedin,
    AiOutlineSolution,
} from 'react-icons/ai';

function HeroSection() {
    return (
        <section id="intro" className="hero-section">
            <div className="hero-section__content">
                <h2 className="hero-section__greet">Hello!</h2>

                <div className="hero-section__row">
                    <div className="hero-section__container hero-section__container--tagline">
                        <p className="hero-section__tagline">
                            I'm <strong>Damien Switzer</strong>, an experienced
                            full stack developer with a passion for creating
                            innovative and user-friendly applications.
                        </p>
                    </div>
                    <div className="hero-section__container  hero-section__container--avatar">
                        <Avatar
                            src={profilePhoto}
                            alt="Damien Switzer"
                            size={200}
                        />
                    </div>
                </div>

                <div className="hero-section__container">
                    <div className="hero-section__links">
                        <a
                            href="https://github.com/ThePinkFedora"
                            className="hero-section__link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button className="hero-section__icon-button">
                                <AiFillGithub size="60" color="#000000" />
                                <span className="hero-section__link-text">
                                    GitHub
                                </span>
                            </button>
                        </a>

                        <a
                            href="https://www.linkedin.com/in/damien-switzer/"
                            className="hero-section__link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <button className="hero-section__icon-button">
                                <AiOutlineLinkedin size="60" color="#2b6cb0" />
                                <span className="hero-section__link-text">
                                    LinkedIn
                                </span>
                            </button>
                        </a>

                        <a
                            href="/damien-switzer-resume.pdf"
                            className="hero-section__link"
                            target="_blank"
                        >
                            <button className="hero-section__icon-button">
                                <AiOutlineSolution size="60" color="#4a505c" />
                                <span className="hero-section__link-text">
                                    Resume
                                </span>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default HeroSection;
