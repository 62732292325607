import './AboutSection.scss';

function AboutSection() {
    return (
        <section id="about" className="about-section">
            <div className="about-section__content">
                <h2 className="about-section__heading">About me</h2>
                {/* <p className="about-section__body">
                    I am an experienced full stack developer currently living in Nanaimo, Canada,
                    with 8+ years of experience in mobile and web development, programming, and
                    application design. I have an extensive knowledge base of C#, Java, Swift,
                    JavaScript, HTML, CSS, jQuery, PHP, SQL, Dart, and other programming languages.
                    My skills include debugging, unit testing, SEO optimization, and source control
                    management. I have a proven track record of successful projects, including the
                    development of mobile games and contracted business applications for Android and
                    iOS.
                </p>
                <p className="about-section__body">
                    On a personal level, I have great communication and social skills, am a quick
                    learner, and can easily adapt to changing procedures. I'm organized, analytical,
                    and multi-task oriented, with a strong work ethic and the ability to meet
                    deadlines. I'm passionate about creating innovative and user-friendly mobile and
                    web applications.
                </p> */}
                <p className="about-section__body">
                    Hey there, Damien here, a seasoned tech enthusiast who
                    recently graduated from the BrainStation Bootcamp. With over
                    a decade in the tech industry, I'm driven by a fervor for
                    continual learning and problem-solving.
                </p>
                <p className="about-section__body">
                    My journey has led me from mobile app development to the
                    dynamic realm of web development, appreciating its limitless
                    potential. My expertise lies in crafting highly efficient
                    and scalable applications. I specialize in scripting, system
                    development, and seamless API integration, focusing on the
                    technical intricacies that underpin user-friendly,
                    cutting-edge web applications.
                </p>
                <p className="about-section__body">
                    If you're seeking an experienced and adaptable full-stack
                    developer, let's connect and chat virtually to discuss
                    favorite tech trends or exciting projects. Together, we can
                    create something truly extraordinary!
                </p>
            </div>
        </section>
    );
}
export default AboutSection;
