import './SkillsSection.scss';
import skillsData from '../../data/skills.json';

function SkillsSection() {
    return (
        <section id="skills" className="skills-section">
            <div className="skills-section__content">
                <h2 className="skills-section__heading">Skills</h2>
                <SkillsList skills={skillsData.skills as SkillEntry[]} />
            </div>
        </section>
    );
}
type SkillEntry = {
    name: string;
    proficiency: 'Beginner' | 'Intermediate' | 'Advanced' | 'Expert';
    icon?: string;
};

interface SkillsListProps {
    skills: SkillEntry[];
}

function SkillsList({ skills }: SkillsListProps) {
    return (
        <ul className="skills-list">
            {skills.map((skill: SkillEntry) => (
                // <li className="skills-list__item">
                //     {skill.name} - {skill.proficiency}{" "}
                //     {skill.icon ? (
                //         <img className="skills-list__icon" src={skill.icon} alt={skill.name} />
                //     ) : null}
                // </li>
                <SkillItem skill={skill} />
            ))}
        </ul>
    );
}

function SkillItem({ skill }: { skill: SkillEntry }) {
    return (
        <li className="skills-list__item">
            {skill.icon ? (
                <img
                    className="skills-list__icon"
                    src={skill.icon}
                    alt={skill.name}
                />
            ) : null}
            <p className="skills-list__text">{skill.name}</p>
        </li>
    );
}

export default SkillsSection;
