import './Navbar.scss';

function Navbar() {
    const handleLinkClick = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        //Smooth scroll to section
        event.preventDefault();
        event.stopPropagation();
        const targetId = event.currentTarget.getAttribute('href');
        if (targetId) {
            document
                .querySelector(targetId)
                ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <header className="navbar">
            <div className="navbar__content">
                <nav className="navbar__nav">
                    <ul className="navbar__nav-list">
                        <li className="navbar__nav-item">
                            <a
                                href="#intro"
                                className="navbar__nav-link"
                                onClick={handleLinkClick}
                            >
                                Home
                            </a>
                        </li>
                        <li className="navbar__nav-item">
                            <a
                                href="#about"
                                className="navbar__nav-link"
                                onClick={handleLinkClick}
                            >
                                About
                            </a>
                        </li>
                        <li className="navbar__nav-item">
                            <a
                                href="#skills"
                                className="navbar__nav-link"
                                onClick={handleLinkClick}
                            >
                                Skills
                            </a>
                        </li>

                        <li className="navbar__nav-item">
                            <a
                                href="#projects"
                                className="navbar__nav-link"
                                onClick={handleLinkClick}
                            >
                                Projects
                            </a>
                        </li>
                        <li className="navbar__nav-item">
                            <a
                                href="#contact"
                                className="navbar__nav-link"
                                onClick={handleLinkClick}
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}
export default Navbar;
