import './Avatar.scss';

interface AvatarProps {
    src: string;
    alt: string;
    size: number;
}

function Avatar({ src, alt, size }: AvatarProps) {
    return (
        <img
            className="avatar"
            src={src}
            alt={alt}
            style={{
                width: `${size}px`,
                height: `${size}px`,
            }}
        />
    );
}
export default Avatar;
