import ProjectCard from '../ProjectCard/ProjectCard';
import './ProjectList.scss';

interface ProjectListProps {
    projects: object[];
}

function ProjectList({ projects }: ProjectListProps) {
    return (
        <div className="project-list">
            {projects.map((project, index) => (
                <ProjectCard key={index} project={project} />
            ))}
        </div>
    );
}
export default ProjectList;
