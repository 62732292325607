import axios from 'axios';
const config = {
    baseUrl:
        'https://learntocode-735d1fa1739f.herokuapp.com/api/contact/damienswitzer',
};

type ContactFormData = {
    name: string;
    email: string;
    message: string;
};

export async function postContactForm(formData: ContactFormData) {
    try {
        const response = await axios.post(`${config.baseUrl}`, formData);
        return response;
    } catch (error: any) {
        console.log(error);
    }
}
