import HeroSection from '../../sections/HeroSection/HeroSection';
import AboutSection from '../../sections/AboutSection/AboutSection';
import ProjectsSection from '../../sections/ProjectsSection/ProjectsSection';
import SkillsSection from '../../sections/SkillsSection/SkillsSection';
import ContactSection from '../../sections/ContactSection/ContactSection';

function HomePage() {
    return (
        <main className="home-page">
            {/* <PageContainer> */}
            <HeroSection />
            <AboutSection />
            <SkillsSection />
            <ProjectsSection />
            <ContactSection />
            {/* </PageContainer> */}
        </main>
    );
}
export default HomePage;
